import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "challenge"
    }}>{`Challenge`}</h1>
    <p>{`Uber has built a cool suite of data visualization tools for WebGL. Let's
explore`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactviz.holiday/datasets/ugr-sankey-openspending.json"
      }}>{`Dataset`}</a></p>
    <h1 {...{
      "id": "my-solution"
    }}>{`My Solution`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/hwIy2dYe6hc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <iframe src="https://codesandbox.io/embed/kkr948o597?fontsize=14" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <p>{`Today was not a great success so tomorrow's gonna be part two.`}</p>
    <p>{`We explored Uber's suite of WebGL-based data visualization libraries from
`}<a parentName="p" {...{
        "href": "http://vis.gl/"
      }}>{`vis.gl`}</a>{`. There's a couple:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`deck.gl`}</inlineCode>{` is for layering data visualization layers on top of maps`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`luma.gl`}</inlineCode>{` is the base library that everything else uses`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`react-map-gl`}</inlineCode>{` is a React-based base layer for maps, you then use deck.gl to
add layers`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`react-vis`}</inlineCode>{` is Uber's take on the "react abstraction for charts" class of
libraries. Renders to SVG`}</li>
    </ol>
    <p>{`Trying out `}<a parentName="p" {...{
        "href": "https://luma.gl/"
      }}>{`luma.gl`}</a>{` seemed like the best way to get started.
It powers everything else and if we're gonna build custom stuff ... well.`}</p>
    <p>{`Implementing this example of wandering triangles seemed like a good idea.`}</p>
    <iframe src="https://luma.gl/#/examples/core-examples/transform-webgl-2" width="100%" height="500" />
    <p>{`Copy pasting a bunch of code and trying to understand what it does even better.`}</p>
    <p>{`So we built a React harness, spelunked through the demo code, and learned a few
things about WebGL.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<LumaFragment component="canvas" />
`}</code></pre>
    <p><inlineCode parentName="p">{`<LumaFragment>`}</inlineCode>{` is a `}<a parentName="p" {...{
        "href": "https://d3blackbox.com"
      }}>{`d3blackbox`}</a>{` component. It
creates a canvas element and lets our render function take over. Our function
is mostly a copy paste job.`}</p>
    <p>{`In this we learned that WebGL renders onto `}<inlineCode parentName="p">{`<canvas>`}</inlineCode>{`. Somehow I never realized
that before.`}</p>
    <p>{`We create LumaFragment like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const LumaFragment = d3blackbox((anchor, props) => {
    // copy pasta code from official example

    animationLoop.start({ canvas: anchor.current });
}
`}</code></pre>
    <p>{`The official example creates an `}<inlineCode parentName="p">{`animationLoop`}</inlineCode>{` based on the `}<inlineCode parentName="p">{`AnimationLoop`}</inlineCode>{`
import from luma.gl. It works like a game loop approach I believe.`}</p>
    <p>{`There's 3 event callbacks the code hooks into:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`onInitialize`}</inlineCode>{`, which initializes objects and sets up the visualization`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`onRender`}</inlineCode>{`, which runs on every loop of the animation. I think`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`onFinalize`}</inlineCode>{`, which runs when the animation runs out, but it looks like
that's never`}</li>
    </ol>
    <p>{`I'd explain the code inside those callbacks here, if I understood it well
enough. Right now it still looks a little alien and hard to grok.`}</p>
    <p>{`What I `}<em parentName="p">{`can`}</em>{` tell you, however, it's that it is designed to run fast. There's a
lot of low level stuff creeping in. Like using `}<inlineCode parentName="p">{`Float32Array`}</inlineCode>{` instead of just
Array because it's typed.`}</p>
    <p>{`Yes, some JavaScript is typed didn't you know?`}</p>
    <p>{`And it uses a lot of Buffers. Those are low level memory blocks with direct
access from the GPU. Makes it faster than working with higher level JavaScript
abstractions.`}</p>
    <p>{`Another trick for more speed is that much of the hard logic happens in shaders.`}</p>
    <p>{`We put our shaders in the `}<inlineCode parentName="p">{`shaders.js`}</inlineCode>{` file. Shaders are low-level GPU code,
originally named after shading effects, but doing all sorts of stuff these
days.`}</p>
    <p>{`Our example uses 3 shaders: `}<inlineCode parentName="p">{`EMIT_VS`}</inlineCode>{`, `}<inlineCode parentName="p">{`DRAW_VS`}</inlineCode>{`, `}<inlineCode parentName="p">{`DRAW_FS`}</inlineCode>{`. They seem to
control how our triangles render and behave, but I haven't been able to figure
out how the JavaScript part ties together with the shaders part.`}</p>
    <p>{`Guess that's our next step.`}</p>
    <p>{`Also figuring out why this happens:`}</p>
    <p>{`Join me tomorrow. We'll have fun :)`}</p>
    <blockquote className="twitter-tweet" data-lang="en"><p lang="en" dir="ltr">That went well ...<br /><br />We continue tomorrow!<br /><br />👉 <a href="https://t.co/I0MfSzduvn">https://t.co/I0MfSzduvn</a> <a href="https://t.co/DECiZJHPXN">pic.twitter.com/DECiZJHPXN</a></p>&mdash; Swizec Teller (@Swizec) <a href="https://twitter.com/Swizec/status/1075059774708953088?ref_src=twsrc%5Etfw">December 18, 2018</a></blockquote>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      